import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  useAuthInfo,
  useLogoutFunction,
  useRedirectFunctions,
} from "@propelauth/react";
import "./Navbar.css";
import userIcon from "./assets/images/profile-icon.png";
import logoBorder from "./assets/images/logo_border.png";
import chromeIcon from "./assets/images/google-chrome.png";

function NavigationBar() {
  const { isLoggedIn } = useAuthInfo();
  const logout = useLogoutFunction();
  const { redirectToSignupPage, redirectToLoginPage } = useRedirectFunctions();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleInstallClick = () => {
    window.open('https://chrome.google.com/webstore/detail/your-extension-id', '_blank');
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <Link to="/">
          <img src={logoBorder} alt="CarTracker" className="navbar-logo" />
        </Link>
      </div>
      <div className="navbar-user-actions">
        <button onClick={handleInstallClick} className="navbar-extension-button">
          <img src={chromeIcon} alt="Chrome" className="chrome-icon-nav" />
          Add to Chrome
        </button>
        {!isLoggedIn && (
          <button
            onClick={redirectToSignupPage}
            className="navbar-signup-button"
          >
            Sign Up
          </button>
        )}
        <div className="navbar-user-icon">
          <div className="dropdown">
            <img
              src={userIcon}
              alt="User"
              className="user-icon"
              onClick={toggleDropdown}
            />
            <div className={`dropdown-content ${isDropdownOpen ? "show" : ""}`}>
              {!isLoggedIn ? (
                <>
                  <button
                    onClick={redirectToLoginPage}
                    className="navbar-auth-button"
                  >
                    Login
                  </button>
                  <button
                    onClick={redirectToSignupPage}
                    className="navbar-auth-button"
                  >
                    Sign Up
                  </button>
                </>
              ) : (
                <>
                  <Link to="/dashboard" onClick={toggleDropdown}>
                    Dashboard
                  </Link>
                  <Link to="/profile" onClick={toggleDropdown}>
                    Profile
                  </Link>
                  <button
                    onClick={() => {
                      logout();
                      toggleDropdown();
                    }}
                    className="navbar-logout-button"
                  >
                    Logout
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavigationBar;
