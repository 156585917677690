import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto">
                <div className="bg-white shadow rounded-lg p-8">
                    <h1 className="text-3xl font-bold text-gray-900 text-center mb-2">
                        Privacy Policy
                    </h1>
                    <p className="text-sm text-gray-600 text-center mb-8">
                        Last updated: January 23, 2025
                    </p>
                </div>

                <div className="prose max-w-none">
                    <section className="mb-8">
                        <h2 className="text-xl font-semibold text-gray-900 mb-4">1. Information We Collect</h2>
                        <p className="text-gray-700 mb-4">
                            Car Tracker collects and processes the following information when you use our Chrome extension:
                        </p>
                        <ul className="list-disc pl-6 text-gray-700 space-y-2">
                            <li>Vehicle information from supported car listing websites</li>
                            <li>Screenshots of vehicle listings and vehicle history reports</li>
                            <li>Your authentication information when you log in</li>
                            <li>Browser data necessary for the extension's functionality</li>
                        </ul>
                    </section>

                    <section className="mb-8">
                        <h2 className="text-xl font-semibold text-gray-900 mb-4">2. How We Use Your Information</h2>
                        <p className="text-gray-700 mb-4">
                            We use the collected information to:
                        </p>
                        <ul className="list-disc pl-6 text-gray-700 space-y-2">
                            <li>Provide car tracking and analysis services</li>
                            <li>Save and organize vehicle listings in your dashboard</li>
                            <li>Analyze vehicle history reports</li>
                            <li>Improve our services and user experience</li>
                            <li>Authenticate users and maintain account security</li>
                        </ul>
                    </section>

                    <section className="mb-8">
                        <h2 className="text-xl font-semibold text-gray-900 mb-4">3. Data Storage and Security</h2>
                        <p className="text-gray-700 mb-4">
                            We implement appropriate security measures to protect your information:
                        </p>
                        <ul className="list-disc pl-6 text-gray-700 space-y-2">
                            <li>All data is encrypted in transit and at rest</li>
                            <li>Access to user data is strictly controlled and monitored</li>
                            <li>Regular security audits and updates are performed</li>
                            <li>Data is stored on secure servers in the United States</li>
                        </ul>
                    </section>

                    <section className="mb-8">
                        <h2 className="text-xl font-semibold text-gray-900 mb-4">4. Data Sharing</h2>
                        <p className="text-gray-700">
                            We do not sell or share your personal information with third parties. Your data is only used to provide and improve our services. We may share anonymized, aggregated data for analytical purposes.
                        </p>
                    </section>

                    <section className="mb-8">
                        <h2 className="text-xl font-semibold text-gray-900 mb-4">5. Your Rights</h2>
                        <p className="text-gray-700 mb-4">
                            You have the right to:
                        </p>
                        <ul className="list-disc pl-6 text-gray-700 space-y-2">
                            <li>Access your personal data</li>
                            <li>Request deletion of your data</li>
                            <li>Export your data</li>
                            <li>Opt-out of data collection</li>
                            <li>Update or correct your information</li>
                        </ul>
                    </section>

                    <section className="mb-8">
                        <h2 className="text-xl font-semibold text-gray-900 mb-4">6. Chrome Extension Permissions</h2>
                        <p className="text-gray-700 mb-4">
                            Our extension requires the following permissions:
                        </p>
                        <ul className="list-disc pl-6 text-gray-700 space-y-2">
                            <li>activeTab: To interact with car listing pages</li>
                            <li>storage: To save your preferences and authentication state</li>
                            <li>scripting: To analyze vehicle information on supported websites</li>
                        </ul>
                    </section>

                    <section className="mb-8">
                        <h2 className="text-xl font-semibold text-gray-900 mb-4">7. Contact Us</h2>
                        <p className="text-gray-700">
                            For any privacy-related questions or concerns, please contact us at:
                        </p>
                        <p className="text-gray-700 mt-2">
                            Email: privacy@carclump.com<br />
                        </p>
                    </section>

                    <section className="mb-8">
                        <h2 className="text-xl font-semibold text-gray-900 mb-4">8. Changes to This Policy</h2>
                        <p className="text-gray-700">
                            We may update this privacy policy from time to time. We will notify users of any material changes through the extension or email. Continued use of our service after such modifications constitutes acceptance of the updated policy.
                        </p>
                    </section>
                </div>
            </div>
        </div>
    );
}; 

export default PrivacyPolicy;