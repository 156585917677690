import React from 'react';
import './ExtensionSection.css';
import chromeIcon from './assets/images/google-chrome.png';

const ExtensionSection = () => {
    const handleInstallClick = () => {
        window.open('https://chrome.google.com/webstore/detail/your-extension-id', '_blank');
    };

    return (
        <div className="extension-section">
            <div className="extension-content">
                <h2>Install Our Chrome Extension to Begin Saving Vehicles and Analyzing Carfax Reports</h2>
                <div className="install-container">
                    <button onClick={handleInstallClick} className="install-button">
                        <img src={chromeIcon} alt="Chrome" className="chrome-icon" />
                        Add to Chrome
                    </button>
                    <p className="install-steps">
                        After installation, click the Car Tracker icon in your browser to start saving vehicles
                    </p>
                </div>
                {/* <div className="extension-features">
    <div className="extension-feature">
        <span className="feature-icon">🚀</span>
        <p>One-click saving from any car listing site</p>
    </div>
    <div className="extension-feature">
        <span className="feature-icon">🎯</span>
        <p>Automatically extracts vehicle details</p>
    </div>
    <div className="extension-feature">
        <span className="feature-icon">⚡</span>
        <p>Instantly sync to your dashboard</p>
    </div>
</div> */}

            </div>
        </div>
    );
};

export default ExtensionSection;