import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

const ConditionScore = ({ car, carfaxVins }) => {
    const [score, setScore] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Memoize the VIN check to prevent unnecessary re-renders
    const hasValidVin = useMemo(() => {
        return car.vin && carfaxVins.has(car.vin.trim().toUpperCase());
    }, [car.vin, carfaxVins]);

    useEffect(() => {
        let isMounted = true;

        const fetchScore = async () => {
            if (!hasValidVin || isLoading) {
                return;
            }

            setIsLoading(true);
            try {
                const response = await fetch(`/api/cars/get-carfax-analysis/${car.id}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                    }
                });

                if (!isMounted) return;

                if (response.ok) {
                    const data = await response.json();
                    setScore(data.condition_rating);
                    setError(null);
                } else {
                    throw new Error(`Error: ${response.status}`);
                }
            } catch (err) {
                if (isMounted) {
                    console.error('Error fetching condition score:', err);
                    setError('Failed to load score');
                }
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        };

        fetchScore();

        return () => {
            isMounted = false;
        };
    }, [car.id, hasValidVin]);

    // Memoize the score styles to prevent unnecessary recalculations
    const scoreStyles = useMemo(() => {
        if (score === null) {
            return {
                background: 'bg-gray-100',
                text: 'text-gray-500'
            };
        }

        if (score >= 90) return {
            background: 'bg-green-100',
            text: 'text-green-800'
        };
        if (score >= 80) return {
            background: 'bg-emerald-100',
            text: 'text-emerald-800'
        };
        if (score >= 70) return {
            background: 'bg-lime-100',
            text: 'text-lime-800'
        };
        if (score >= 60) return {
            background: 'bg-yellow-100',
            text: 'text-yellow-800'
        };
        if (score >= 50) return {
            background: 'bg-orange-100',
            text: 'text-orange-800'
        };
        return {
            background: 'bg-red-100',
            text: 'text-red-800'
        };
    }, [score]);

    if (!car.vin) {
        return (
            <div className="text-sm text-gray-500">
                No VIN
            </div>
        );
    }

    if (!hasValidVin) {
        return (
            <div className="text-sm text-gray-500">
                No Report
            </div>
        );
    }

    return (
        <Link to={`/carfax-analysis/${car.id}`}>
            <div className={`inline-block rounded-md px-2 py-1 min-w-[4rem] text-center transition-all
                ${error ? 'bg-red-100 text-red-800' :
                    score !== null ? `${scoreStyles.background} ${scoreStyles.text}` :
                        'bg-gray-100 text-gray-500'}`}>
                {error ? (
                    'Error'
                ) : score !== null ? (
                    Math.round(score)
                ) : (
                    'Loading...'
                )}
            </div>
        </Link>
    );
};

export default React.memo(ConditionScore);