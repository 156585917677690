import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchCarfaxAnalysis } from '../api';
import './CarfaxAnalysis.css';

function CarfaxAnalysis() {
    const [analysis, setAnalysis] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [authToken, setAuthToken] = useState(localStorage.getItem('jwtToken'));
    const { carId } = useParams();
    const navigate = useNavigate();

    // Add token check effect (similar to Dashboard.js)
    useEffect(() => {
        const checkToken = () => {
            const token = localStorage.getItem('jwtToken');
            if (token) {
                setAuthToken(token);
            }
        };

        // Check immediately
        checkToken();

        // Set up an interval to check periodically
        const interval = setInterval(checkToken, 1000);

        // Clean up
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        console.log('Current states:', { loading, analysis, error });
        console.log('CarfaxAnalysis component mounted with ID:', carId);

        const fetchAnalysis = async () => {
            if (!authToken) {
                console.log('Waiting for auth token...');
                return;
            }

            setLoading(true);
            try {
                console.log(`Fetching analysis for car ID: ${carId}`);
                const data = await fetchCarfaxAnalysis(carId, authToken);
                console.log('Received data:', data);

                if (!data) {
                    throw new Error('No data received from server');
                }

                const transformedData = {
                    condition_rating: data.condition_rating || 0,
                    risk_score: data.risk_score || 0,
                    maintenance_score: data.maintenance_score || 0,
                    number_of_owners: data.number_of_owners,
                    accident_count: data.accident_count,
                    last_reported_mileage: data.last_reported_mileage,
                    service_history: data.service_history || [],
                    location_history: data.location_history || {},
                    major_issues: data.major_issues || [],
                    maintenance_analysis: data.maintenance_analysis || {}
                };

                console.log('Setting analysis state with:', transformedData);
                setAnalysis(transformedData);
                setError(null);
            } catch (error) {
                console.error('Fetch error:', error);
                console.error('Error stack:', error.stack);
                setError(error.response?.data?.error || error.message || 'Failed to fetch Carfax analysis');
            } finally {
                console.log('Setting loading to false');
                setLoading(false);
            }
        };

        if (carId && authToken) {
            fetchAnalysis();
        }
    }, [carId, authToken]); // Add authToken to dependencies

    const getScoreColor = (score) => {
        if (score >= 80) return '#28a745';
        if (score >= 60) return '#ffc107';
        return '#dc3545';
    };

    if (loading) {
        return (
            <div className="carfax-analysis-container">
                <div className="analysis-content">Loading Carfax analysis...</div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="carfax-analysis-container">
                <div className="carfax-header">
                    <h2>Carfax Analysis</h2>
                    <button className="back-button" onClick={() => navigate('/dashboard')}>
                        Back to Dashboard
                    </button>
                </div>
                <div className="analysis-content">
                    <p className="error-message">Error: {error}</p>
                </div>
            </div>
        );
    }

    if (!analysis) {
        return (
            <div className="carfax-analysis-container">
                <div className="carfax-header">
                    <h2>Carfax Analysis</h2>
                    <button className="back-button" onClick={() => navigate('/dashboard')}>
                        Back to Dashboard
                    </button>
                </div>
                <div className="analysis-content">
                    <p>No Carfax analysis available for this vehicle.</p>
                </div>
            </div>
        );
    }

    return (
        <div className="carfax-analysis-container">
            <div className="carfax-header">
                <h2>Carfax Analysis</h2>
                <button className="back-button" onClick={() => navigate('/dashboard')}>
                    Back to Dashboard
                </button>
            </div>

            <div className="analysis-content">
                <div className="metrics-grid">
                    <div className="metric-card">
                        <div className="metric-label">Condition Rating</div>
                        <div className="metric-value">{analysis.condition_rating?.toFixed(1) || 'N/A'}</div>
                        <div className="score-bar">
                            <div
                                className="score-fill"
                                style={{
                                    width: `${analysis.condition_rating || 0}%`,
                                    backgroundColor: getScoreColor(analysis.condition_rating)
                                }}
                            />
                        </div>
                    </div>

                    <div className="metric-card">
                        <div className="metric-label">Risk Score</div>
                        <div className="metric-value">{analysis.risk_score?.toFixed(1) || 'N/A'}</div>
                        <div className="score-bar">
                            <div
                                className="score-fill"
                                style={{
                                    width: `${100 - (analysis.risk_score || 0)}%`,
                                    backgroundColor: getScoreColor(100 - analysis.risk_score)
                                }}
                            />
                        </div>
                    </div>

                    <div className="metric-card">
                        <div className="metric-label">Maintenance Score</div>
                        <div className="metric-value">{analysis.maintenance_score?.toFixed(1) || 'N/A'}</div>
                        <div className="score-bar">
                            <div
                                className="score-fill"
                                style={{
                                    width: `${analysis.maintenance_score || 0}%`,
                                    backgroundColor: getScoreColor(analysis.maintenance_score)
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="analysis-section">
                    <h3>Vehicle History</h3>
                    <div className="metrics-grid">
                        <div className="metric-card">
                            <div className="metric-label">Number of Owners</div>
                            <div className="metric-value">{analysis.number_of_owners || 'N/A'}</div>
                        </div>
                        <div className="metric-card">
                            <div className="metric-label">Accident Count</div>
                            <div className="metric-value">{analysis.accident_count || '0'}</div>
                        </div>
                        <div className="metric-card">
                            <div className="metric-label">Last Reported Mileage</div>
                            <div className="metric-value">
                                {analysis.last_reported_mileage ?
                                    `${analysis.last_reported_mileage.toLocaleString()} mi` :
                                    'N/A'}
                            </div>
                        </div>
                    </div>
                </div>

                {analysis.service_history && analysis.service_history.length > 0 && (
                    <div className="analysis-section">
                        <h3>Service History</h3>
                        {analysis.service_history.map((record, index) => (
                            <div key={index} className="service-record">
                                <div className="service-date">
                                    {record.date instanceof Date ?
                                        record.date.toLocaleDateString() :
                                        new Date(record.date).toLocaleDateString()}
                                </div>
                                <div className="service-description">{record.service}</div>
                            </div>
                        ))}
                    </div>
                )}

                {analysis.location_history && (
                    <div className="analysis-section">
                        <h3>Location History</h3>
                        <div className="location-history">
                            {analysis.location_history.rust_belt_exposure && (
                                <div className="rust-belt-warning">
                                    ⚠️ This vehicle has been exposed to rust belt conditions
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {analysis.major_issues && analysis.major_issues.length > 0 && (
                    <div className="analysis-section">
                        <h3>Major Issues</h3>
                        <ul className="major-issues-list">
                            {analysis.major_issues.map((issue, index) => (
                                <li key={index} className="major-issue-item">
                                    {issue}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                {analysis.maintenance_analysis && Object.keys(analysis.maintenance_analysis).length > 0 && (
                    <div className="analysis-section">
                        <h3>Maintenance Analysis</h3>
                        <div className="service-record">
                            <pre>{JSON.stringify(analysis.maintenance_analysis, null, 2)}</pre>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default CarfaxAnalysis;