import { useEffect, useRef } from "react";
import { useAuthInfo } from "@propelauth/react";

// API base URL for making authenticated requests
const API_BASE_URL = process.env.REACT_APP_PROD_SERVER_URL;

// Function to send token to extension with retry logic
function sendTokenToExtension(token) {
  const environment = process.env.NODE_ENV;
  const message = {
    type: "FROM_PAGE_TO_EXTENSION",
    token: token,
    environment: environment,
  };

  let attempts = 0;
  const maxAttempts = 3;
  const RETRY_DELAY = 1000;

  const sendMessage = () => {
    if (!token) {
      console.error("Cannot send empty token to extension");
      return Promise.reject(new Error("Empty token"));
    }

    window.postMessage(message, "*");
    console.log(`Attempt ${attempts + 1}: Sending token to extension (${environment})`);
  };

  return new Promise((resolve, reject) => {
    const verifyReceipt = (event) => {
      if (event.data && event.data.type === "TOKEN_RECEIVED_BY_EXTENSION") {
        console.log("Token receipt confirmed by extension");
        window.removeEventListener("message", verifyReceipt);
        resolve();
      } else if (attempts < maxAttempts) {
        attempts++;
        console.log(`Retrying token send (${attempts}/${maxAttempts})`);
        setTimeout(() => {
          sendMessage();
        }, RETRY_DELAY);
      } else {
        console.error("Failed to confirm token receipt by extension");
        window.removeEventListener("message", verifyReceipt);
        reject(new Error("Token confirmation failed"));
      }
    };

    window.addEventListener("message", verifyReceipt);

    // Initial send
    sendMessage();

    // Cleanup after timeout
    setTimeout(() => {
      window.removeEventListener("message", verifyReceipt);
      if (attempts >= maxAttempts) {
        reject(new Error("Token confirmation timeout"));
      }
    }, (maxAttempts + 1) * RETRY_DELAY);
  });
}

// Function to handle authentication and store the token locally
// In Authenticate.js
function authenticate(accessToken) {
  console.log("Authenticating with access token:", accessToken ? "present" : "missing");

  if (!accessToken) {
    console.error("Access token is null, authentication cannot proceed.");
    return Promise.reject(new Error("Access token is null"));
  }

  // Add token format validation
  if (typeof accessToken !== 'string' || !accessToken.trim()) {
    console.error("Invalid access token format");
    return Promise.reject(new Error("Invalid access token format"));
  }

  return fetch(`${API_BASE_URL}/auth/api/authenticate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    credentials: "include",
  })
    .then(async (response) => {
      const contentType = response.headers.get("content-type");

      if (response.ok) {
        try {
          const data = await response.json();
          localStorage.setItem("jwtToken", accessToken);
          // Only send token to extension after successful authentication
          await sendTokenToExtension(accessToken);
          return data;
        } catch (error) {
          console.error("Error parsing authentication response:", error);
          throw new Error("Invalid response format from server");
        }
      }

      // Try to get detailed error message from response
      try {
        const errorData = await response.json();
        throw new Error(errorData.message || `Authentication failed with status: ${response.status}`);
      } catch (e) {
        throw new Error(`Authentication failed with status: ${response.status}`);
      }
    })
    .catch((error) => {
      console.error("Authentication error:", error.message);
      // Clear any invalid tokens
      localStorage.removeItem("jwtToken");
      throw error;
    });
}

function AuthenticatedRequest() {
  const { isLoggedIn, accessToken, refresh } = useAuthInfo();
  const lastRunTimeRef = useRef(0);
  const lastTokenRef = useRef(null);
  const retryCountRef = useRef(0);
  const MAX_RETRIES = 3;

  useEffect(() => {
    let isMounted = true;
    const RETRY_DELAY = 1000; // 1 second
    const TOKEN_EXPIRY_TIME = 3600000; // 1 hour

    const handleAuthentication = async () => {
      const currentTime = Date.now();
      const timeSinceLastRun = currentTime - lastRunTimeRef.current;

      if (!isLoggedIn || !accessToken) {
        console.log("User not logged in or access token missing");
        // Clear any stale data
        localStorage.removeItem("jwtToken");
        retryCountRef.current = 0;
        return;
      }

      // Check if we need to authenticate
      if (accessToken === lastTokenRef.current &&
        timeSinceLastRun < TOKEN_EXPIRY_TIME &&
        localStorage.getItem("jwtToken")) {
        console.log("Using cached authentication");
        return;
      }

      try {
        console.log("Attempting authentication...");
        const response = await authenticate(accessToken);

        if (isMounted) {
          console.log("Authentication successful");
          lastRunTimeRef.current = currentTime;
          lastTokenRef.current = accessToken;
          retryCountRef.current = 0;
        }
      } catch (error) {
        console.error("Authentication error:", error.message);

        // Handle different error cases
        if (error.message.includes("500")) {
          if (retryCountRef.current < MAX_RETRIES) {
            retryCountRef.current++;
            console.log(`Retrying authentication (${retryCountRef.current}/${MAX_RETRIES})...`);
            setTimeout(handleAuthentication, RETRY_DELAY);
          } else {
            console.error("Max retries reached, clearing authentication state");
            localStorage.removeItem("jwtToken");
            retryCountRef.current = 0;
            if (refresh) {
              refresh(); // Trigger PropelAuth refresh if available
            }
          }
        } else {
          // Clear invalid authentication state
          localStorage.removeItem("jwtToken");
          retryCountRef.current = 0;
        }
      }
    };

    handleAuthentication();

    return () => {
      isMounted = false;
    };
  }, [isLoggedIn, accessToken, refresh]);

  return null;
}

// Log the current JWT token presence
console.log("JWT token in LocalStorage:", !!localStorage.getItem("jwtToken"));

export default AuthenticatedRequest;